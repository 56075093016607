import React, { useEffect, useState } from "react";
import { GridApi, ICellRendererParams } from "ag-grid-community";
import ContentLayout from "../../../components/shared/layout/ContentLayout";
import strings from "./constants/strings";
import {
  createNewCarCatalogCols,
  createSwitchRenderer,
} from "./utils/table-utils";
import NewCarCatalog from "../../../models/NewCarCatalog";
import TableLayout, { TableHeaderAction } from "../../../components/shared/layout/TableLayout";
import {
  fetchNCCatalogs,
  updateNCCatalogDisplayState,
  updateNCCatalogDisplayStateList,
} from "../../../apis/nc-catalogs";
import { alertError, alertSuccess } from "../../../utils/render-utils";
import ClientRowTable from "../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createActionCellRenderers } from "../../../utils/table-utils";
import NewCarCatalogModal from "./pages/NewCarCatalogModal";
import { showConfirm } from "../../../components/shared/feedback/Confirm";
import { getErrorMessage } from "../../../utils/common-utils";
import CatalogSortOrderModal from "./pages/CatalogSortOrderModal";
import { ProdUseYn } from "@models/NewCarCatalogDetail";

const NewCarCatalogManager: React.FC = () => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const cols = createNewCarCatalogCols();
  const [rows, setRows] = useState<Array<NewCarCatalog>>();
  const [totalCount, setTotalCount] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDataId, setSelectedDataId] = useState<string>();
  const [sortOrderModalVisible, setSortOrderModalVisible] = useState(false);
  // 상품 선택 목록
  const [checkedRows, setCheckedRows] = useState<Array<ProdUseYn>>([]);
  const [isApiCall, setIsApiCall] = useState<boolean>(false);

  useEffect(() => {
    (async function fetchData() {
      await requestFetchNCCatalogs();
    })();
  }, []);

  /**
   * Private Functions
   */

  const requestFetchNCCatalogs = async () => {
    try {
      const { items, count } = await fetchNCCatalogs();
      setRows(items);
      setTotalCount(count);
    } catch (e) {
      if (e.status !== 404) {
        if (e.getMessage) alertError(e.getMessage());
        else alertError(e.toString());
      }
      setRows([]);
    }
  };

  const requestUpdateDisplayState = async (
    prodId: string,
    checked: boolean
  ) => {
    try {
      await updateNCCatalogDisplayState(prodId, checked);
      alertSuccess(strings.FEEDBACK_DISPLAY_STATE_CHANGE);
      await requestFetchNCCatalogs();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * 상품 노출여부 일괄 갱신
   */
  const requestUpdateDisplayStateList = async () => {
    try {
      await updateNCCatalogDisplayStateList(checkedRows);
      alertSuccess(strings.FEEDBACK_DISPLAY_STATE_CHANGE);
      await requestFetchNCCatalogs();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * Event Actions
   */

  const handleDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { prodId } = data;
    setSelectedDataId(prodId);
    setModalVisible(true);
  };

  const handleDisplayStateSwitchChange = (checked: boolean, prodId: string) => {
    showConfirm(
      checked ? "노출상태로 변경" : "숨김상태로 변경",
      checked
        ? "해당상품을 노출상태로 변경하시겠습니까?"
        : "해당상품을 숨김상태로 변경하시겠습니까?",
      async () => {
        await requestUpdateDisplayState(prodId, checked);
      }
    );
  };

  /**
   * 상품 노출여부 일괄 갱신 confirm
   * 
   * @param isShow 
   */
  const handleDisplayStateListChange = (isShow: boolean) => {
    showConfirm(
      isShow ? "일괄 노출 처리" : "일괄 비노출 처리리",
      "노출 상태를 변경하시겠습니까?",
      async () => {
        // checkedRows.forEach(row => row.useYn = isShow ? "Y" : "N");
        setCheckedRows(prevRows => 
          prevRows.map(row => ({
            ...row,
            useYn: isShow ? "Y" : "N"
          }))
        );
        // await requestUpdateDisplayStateList(checkedRows);
        setIsApiCall(true);
      }
    );
  };
  useEffect(() => {
    if (isApiCall) {
      setIsApiCall(false);
      requestUpdateDisplayStateList();
    }
  }, [isApiCall]);

  /**
   * Render Helpers
   */

  /**
   * 좌측 버튼 설정
   * 
   * @returns 
   */
  const createLeftActions = (): TableHeaderAction[] => [
    {
      title: "일괄 노출 처리",
      onClick: () => {
        handleDisplayStateListChange(true);
      },
      disabled: checkedRows.length === 0,
    },
    {
      title: "일괄 비노출 처리",
      onClick: () => {
        handleDisplayStateListChange(false);
      },
      type: "ghost",
      danger: true,
      disabled: checkedRows.length === 0,
    },
  ];

  /**
   * 우측 버튼 설정
   * 
   * @returns 
   */
  const createRightActions = () => {
    return [
      {
        title: "게시순번 관리",
        onClick: () => {
          setSortOrderModalVisible(true);
        },
      },
    ];
  };

  /** checkbox 제어 */
  const handleCheckRowChanged = (rows: Array<NewCarCatalog>) => {
    setCheckedRows(rows?.map(row => {
      return { prodId: row.prodId, useYn: ''};
    }));
  };

  return (
    <>
      <ContentLayout
        headerTitle={strings.TITLE_HEADER_NC_CATALOG_MANAGER}
        description={strings.DESCRIPTION_HEADER_NC_CATALOG_MANAGER}
      >
        <TableLayout
          gridApi={gridApi}
          title={strings.TABLE_HEADER_NC_CATALOGS}
          totalCount={totalCount}
          hideSelectionCountText
          leftActions={createLeftActions()}
          rightActions={createRightActions()}
          methodName="/admin/nc-catalogs"
        >
          <ClientRowTable
            apiRef={(api) => {
              setGridApi(api);
            }}
            onCheckRowChanged={handleCheckRowChanged}
            cols={cols}
            rowData={rows}
            frameworkComponents={{
              ...createActionCellRenderers(undefined, handleDetailClick, {
                hideDeleteButton: true,
              }),
              ...createSwitchRenderer(handleDisplayStateSwitchChange),
            }}
          />
        </TableLayout>
        <NewCarCatalogModal
          onDataChange={async () => {
            await requestFetchNCCatalogs();
          }}
          visible={modalVisible}
          dataId={selectedDataId}
          onCancel={() => {
            setModalVisible(false);
          }}
        />
      </ContentLayout>
      <CatalogSortOrderModal
        visible={sortOrderModalVisible}
        onCancel={() => {
          setSortOrderModalVisible(false);
        }}
      />
    </>
  );
};

NewCarCatalogManager.defaultProps = {};
export default NewCarCatalogManager;
