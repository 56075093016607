import React from "react";
import styled from "styled-components";
import { ICellRendererParams } from "ag-grid-community";
import strings from "../constants/strings";
import TableColumn, {
  createTableCol,
  FilterType,
} from "../../../../components/shared/data-display/Tables/model/TableColumn";
import {
  createActionCol,
  CW_DATE_RANGE,
  CW_LONG_TEXT,
  CW_MEDIUM,
  CW_SMALL,
} from "../../../../utils/table-utils";
import Switch from "../../../../components/shared/data-entry/antd/Switch";
import { dateFormatRenderer } from "../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import commonStrings from "../../../../constants/strings";

export const AR_DISPLAY_STATE_SWITCH = "displayStateSwitchRenderer";

const renderSwitch = (onChange: (checked: boolean, prodId: string) => void) => {
  return (props: ICellRendererParams) => {
    const { data } = props;
    const { useYn, prodId } = data;
    const useCheck = useYn === "Y" ? true : false;
    return (
      <Button 
        onClick={() => {
          onChange(useYn !== "Y", prodId);
        }}
        useCheck = {useCheck}
      >
        {useCheck ? "노출" : "비노출"}
      </Button>
    );
  };
};

export const createSwitchRenderer = (
  onChange: (checked: boolean, prodId: string) => void
) => ({
  [AR_DISPLAY_STATE_SWITCH]: renderSwitch(onChange),
});

export const createNewCarCatalogCols = (): Array<TableColumn> => {
  return [
    {
      headerName: "",
      field: "checkbox",
      width: 30,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    // 등록일
    createTableCol("regDtm", strings.COL_REGISTERED_AT, FilterType.Date, {
      width: CW_DATE_RANGE,
      cellRenderer: dateFormatRenderer("regDtm"),
    }),
    // 노출상태
    createTableCol("useYn", strings.COL_DISPLAY_STATE, FilterType.Text, {
      cellRenderer: AR_DISPLAY_STATE_SWITCH,
      width: CW_MEDIUM,
    }),
    // 상품명
    createTableCol("prodNm", strings.COL_CATALOG_NAME, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 판매유형
    createTableCol(
      "ncarClsFlagNm",
      commonStrings.COL_SELLING_TYPE,
      FilterType.Text,
      {
        width: CW_MEDIUM,
      }
    ),
    // 제조사
    createTableCol("brandNm", strings.COL_BRAND, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 모델
    createTableCol("repCarClassNm", strings.COL_MODEL, FilterType.Text, {
      width: CW_MEDIUM,
    }),
    // 세부 모델
    createTableCol("carClassNm", strings.COL_DETAIL_MODEL, FilterType.Text, {
      minWidth: CW_LONG_TEXT,
      flex: 1,
    }),
    // 연식
    createTableCol("yearType", strings.COL_CAR_YEAR, undefined, {
      width: CW_SMALL,
    }),
    // 단산 트림 수
    createTableCol("dnsnCnt", strings.COL_DANSAN_TRIM_COUNT, undefined, {
      width: CW_SMALL,
    }),
    // 등록 트림 수
    createTableCol("prodApyCnt", strings.COL_REG_TRIM_COUNT, undefined, {
      width: CW_SMALL,
    }),
    // 재고 수
    createTableCol("stockCnt", strings.COL_STOCK_COUNT, undefined, {
      width: CW_SMALL,
    }),
    // 트림 수
    createTableCol("prodCondCnt", strings.COL_TRIM_COUNT, undefined, {
      width: CW_SMALL,
    }),
    {
      ...createActionCol(80),
    },
  ];
};

const Button = styled.button<any>`
  background:none;
  border:none;
  outline:none;
  cursor: pointer;
  font-family: "Noto Sans KR", sans-serif;
  text-decoration: underline;
  color:${(props: any) => (props.useCheck ? "blue" : "red")};
`