/* eslint-disable import/no-cycle */
import React, { useState } from "react";
import { Divider } from "antd";
import { ICellRendererParams } from "ag-grid-community";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import strings from "../../constants/strings";
import {
  editBizUserAuthState,
  editBizUserAuthStateContract,
  fetchCustomerContracts,
  fetchCustomerDetail,
  fetchCustomerDetailContract,
  fetchCustomerEstimations,
  editCustomerInfos,
  deleteBusiness,
} from "../../../../../apis/customers";
import {
  alertError,
  alertSuccess,
  renderLoading,
} from "../../../../../utils/render-utils";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import Button from "../../../../../components/shared/general/antd/Button";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import { createContractCols, createEstimationCols } from "./utils/table-utils";
import { getErrorMessage } from "../../../../../utils/common-utils";
import CustomerInfoHistoryListModal from "./pages/CustomerInfoHistoryListModal";
import {
  BIZ_AUTH_TRANSLATION,
  ENUM_BIZ_AUTH_OPTIONS,
  ENUM_CORP_BUSINESS_CODE,
  ENUM_PRIVATE_BUSINESS_CODE,
} from "../../../../../constants/enums";
import BusinessInfo from "../../../../../models/BusinessInfo";
import CustomerDetail from "../../../../../models/CustomerDetail";
import CarManagerTransferRequestModal from "./pages/CarManagerTransferRequestModal";
import ModalTableWrapper from "../../../../../components/shared/layout/ModalTableWrapper";
import Estimation from "../../../../../models/Estimation";
import RentContract from "../../../../../models/RentContract";
import { createActionCellRenderers } from "../../../../../utils/table-utils";
import EstimationSimulatorModal from "../EstimationSimulatorModal";
import { ModalProps } from "../../../../types";
import EstimationDetailModal from "../../../../contracts/EstimationManager/pages/EstimationDetailModal";
import RentContractDetailModal from "../../../../contracts/RentContractManager/pages/RentContractDetailModal";
import { showConfirm } from "../../../../../components/shared/feedback/Confirm";
import { formatDateString } from "../../../../../utils/date-utils";
import Select from "../../../../../components/shared/data-entry/antd/Select";
import CustomSimulSection from "../../../../../components/custom/customer/customSimulSection";
import CustomTMAgreeSection from "../../../../../components/custom/customer/customTMAgreeSection";
import CustomNoteSection from "../../../../../components/custom/customer/customNoteSection";

// modal
import BusinessInfoEditModal from "./pages/BusinessInfoEditModal";
import { BizEditInfo } from "./types";

interface Props extends ModalProps {
  visible: boolean;
  onCancel?: () => void;

  id?: string;
  cntrId?: string;
  cntrSeq?: number;
  editable?: boolean;
}

const CustomerModal: React.FC<Props> = (props: Props) => {
  const {
    visible,
    onCancel,
    id,
    cntrId,
    cntrSeq,
    onDataChange,
    editable,
  } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [customer, setCustomer] = useState<CustomerDetail>();
  const [estRows, setEstRows] = useState<Array<Estimation>>();
  const [businessInfoForEstimate, setBusinessInfoForEstimate] = useState<
    BusinessInfo
  >();
  const [
    estimationSimulatorModalVisible,
    setEstimationSimulatorModalVisible,
  ] = useState(false);

  const [contractRows, setContractRows] = useState<Array<RentContract>>();
  const [
    customerInfoHistoryModalVisible,
    setCustomerInfoHistoryModalVisible,
  ] = useState<boolean>(false);

  const [
    carManagerTransferRequestModalVisible,
    setCarManagerTransferRequestModalVisible,
  ] = useState<boolean>(false);
  const [carmanId, setCarmanId] = useState<string>();

  const estimationCols = createEstimationCols();
  const contractCols = createContractCols();

  const [estDetailModalVisible, setEstDetailModalVisible] = useState(false);
  const [
    rentContractDetailModalVisible,
    setRentContractEstDetailModalVisible,
  ] = useState(false);
  const [selectedEstId, setSelectedEstId] = useState("");
  const [selectedContractId, setSelectedContractId] = useState("");
  const [selectedContractSeq, setSelectedContractSeq] = useState("");

  // 사업자 정보 수정
  const [bizInfo, setBizInfo] = useState<BizEditInfo | null>();
  const [isVisibleEditBizInfo, setVisibleEditBizInfo] = useState(false);

  const onModalOpen = async () => {
    if (id) {
      setDataFetching(true);
      const customerRes = await requestFetchCustomerDetail(id, cntrId, cntrSeq);
      setEstRows(undefined);
      setContractRows(undefined);
      setCustomer(customerRes);
      await requestFetchEstimations(id);
      await requestFetchContracts(id);
      setDataFetching(false);
    } else {
      setCustomer(undefined);
    }
  };

  /**
   * Private Functions
   */

  const requestFetchCustomerDetail = async (
    custId: string,
    cntrId?: string,
    cntrSeq?: number
  ) => {
    try {
      if (cntrId && cntrSeq) {
        return await fetchCustomerDetailContract(custId, cntrId, cntrSeq);
      }
      return await fetchCustomerDetail(custId);
    } catch (e) {
      alertError(getErrorMessage(e));
      return undefined;
    }
  };

  const requestFetchEstimations = async (custId: string) => {
    try {
      const { items } = await fetchCustomerEstimations(custId);
      setEstRows(items);
    } catch (e) {
      alertError(getErrorMessage(e));
      setEstRows([]);
    }
  };

  const requestFetchContracts = async (custId: string) => {
    try {
      const { items } = await fetchCustomerContracts(custId);
      setContractRows(items);
    } catch (e) {
      alertError(getErrorMessage(e));
      setContractRows([]);
    }
  };

  const handleUsernoteUpdateButton = async (usernote:string) => {
    const customerInfo = {
      custNm : customer?.custNm,
      email: customer?.email,
      addr: customer?.addr,
      dtladdr: customer?.dtladdr,
      remark: usernote,
      agreeList: customer?.agreeList
    };
     
    await editCustomerInfos(customer!.custId, customerInfo);
    setDataFetching(true);
    const customerRes =  requestFetchCustomerDetail(id!);
    setCustomer(await customerRes);
    setDataFetching(false);
      
  };

  const handleEstimationSimulatorModal = (businessInfo?: BusinessInfo) => {
    // 개인사업자 또는 법인 견적
    if (businessInfo) {
      // 법인
      if (businessInfo.isCorp) {
        // 법인 견적진행 가능여부 확인
        if (!businessInfo.canCorpEstimate) {
          alertError("견적진행이 불가능한 고객입니다.(담보율 확인불가)");
        } else {
          // 법인 견적 진행
          setBusinessInfoForEstimate(businessInfo);
          setEstimationSimulatorModalVisible(true);
        }
      }
      // 개인사업자
      else if (customer) {
        // 개인사업자는 개인의 신용등급에 따라 진행
        if (customer.canEstimate) {
          setBusinessInfoForEstimate(businessInfo);
          setEstimationSimulatorModalVisible(true);
        } else {
          alertError("견적진행이 불가능한 고객입니다.(계약불가 신용등급)");
        }
      }
    }
    // 개인 견적
    else if (customer) {
      if (customer.canEstimate) {
        setBusinessInfoForEstimate(undefined);
        setEstimationSimulatorModalVisible(true);
      } else {
        alertError(
          "견적진행이 불가능한 고객입니다.(본인인증 미진행 또는 계약불가 신용등급)"
        );
      }
    }
  };

  const handleEstimationDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { estReqNo } = data;
    setSelectedEstId(estReqNo);
    setEstDetailModalVisible(true);
  };

  const handleContractDetailClick = (props: ICellRendererParams) => {
    const { data } = props;
    const { cntrId, cntrSeq } = data;
    setSelectedContractId(cntrId);
    setSelectedContractSeq(cntrSeq);
    setRentContractEstDetailModalVisible(true);
  };

  const requestEditBizUserAuthState = async (bizId: string, authYn: string) => {
    try {
      if (cntrId && cntrSeq) {
        await editBizUserAuthStateContract(bizId, authYn, cntrId, cntrSeq);
      } else {
        await editBizUserAuthState(bizId, authYn);
      }
      if (id) {
        const customerRes = await requestFetchCustomerDetail(id);
        setCustomer(customerRes);
      }
      alertSuccess("사업자 인증상태가 변경되었습니다.");
      if (onDataChange) onDataChange();
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  // 카매니저 이관요청
  const handleCarManagerTransferRequestButtonClick = (carmanId: string) => {
    setCarmanId(carmanId);
    setCarManagerTransferRequestModalVisible(true);
  };

  // TM 동의 변경
  const handleTMSwitch = async (    
    checked: boolean,   
  ) => {

    const customerInfo = {
      "agreeList":[
          {
              "agreClsCd": "A03025",
              "agreYn": checked ? "Y" : "N",
          }
      ]
    }
 
    showConfirm(
      "TM 수신동의여부",
      "TM 수신동의여부를 변경하시겠습니까?",
      async () => {
        await editCustomerInfos(customer!.custId, customerInfo);
        setDataFetching(true);
        const customerRes =  requestFetchCustomerDetail(id!);
        setCustomer(await customerRes);
        setDataFetching(false);
        if (onDataChange) onDataChange();
      }
    );

  };

  /**
   * 사업자 정보 수정 모달 띄우기
   * 
   * @param businessInfo 
   */
  const handleBizEditClick = (businessInfo: BusinessInfo) => {
    const bizInfo: BizEditInfo = {...businessInfo, custNm: customer?.custNm};
    setBizInfo(bizInfo);
    setVisibleEditBizInfo(true);
  };

  /**
   * 모달 닫기
   */
  const handleBizEditCancelClick = () => {
    setBizInfo(null);
    setVisibleEditBizInfo(false);
  };

  /**
   * 사업자 삭제
   * 
   * @param busrCustId 
   */
  const handleBizDelButton = async (busrCustId: string) => {

    showConfirm(
      "사업자 삭제",
      "해당 사업자 정보를 삭제하시겠습니까?",
      async () => {
        await deleteBusiness(busrCustId);
        setDataFetching(true);
        const customerRes = requestFetchCustomerDetail(id!);
        setCustomer(await customerRes);
        setDataFetching(false);
      }
    );
  };

  /**
   * Render Helpers
   */

  // 견적내기 버튼


  // 법인 사업자 정보
  const getCorpFieldInfo = (businessInfo: BusinessInfo) => {

    const {
      busrCustNm,
      corRegNo,
      busiNo,
      taxCustNm,
      taxEmail,
      taxTelNo,
      taxHpNo,
      addr,
      dtladdr,
      bzcd,
      bztp,
      regLicFileUrl,
      kisAuthYn,
      addVrfYn,
      vrfUserNm,
      busrCustId,
    } = businessInfo;

    let corpAddr = "";
    if (addr) {
      corpAddr += addr;
    }
    if (dtladdr) {
      if (corpAddr) corpAddr += ` ${dtladdr}`;
      else corpAddr += dtladdr;
    }

    const custNm = customer?.custNm;

    return [
      {
        label: "법인정보",
        type: FieldType.LabelOnly,
      },
      // 사업자 수정 / 삭제
      {
        type: FieldType.Custom,
        span: 24,
        render: () => {
          return (
            <>
              <Button
                style={{
                  marginRight: "8px",
                }}
                onClick={() => {
                  handleBizEditClick(businessInfo);
                }}
              >
                수정
              </Button>
              <Button
                onClick={() => {
                  handleBizDelButton(businessInfo.busrCustId);
                }}
              >
                삭제
              </Button>
            </>
          );
        },
      },
      {
        label: "법인명",
        type: FieldType.Text,
        content: busrCustNm,
      },
      {
        label: "법인번호",
        type: FieldType.Text,
        content: corRegNo,
      },
      {
        label: "사업자번호",
        type: FieldType.Text,
        content: busiNo,
      },
      {
        label: "대표자명",
        type: FieldType.Text,
        content: custNm,
      },
      {
        label: "업종",
        type: FieldType.Text,
        content: bztp,
      },
      {
        label: "업태",
        type: FieldType.Text,
        content: bzcd,
      },
      {
        label: "사업자 등록증",
        span: 12,
        type: FieldType.Custom,
        render: () => {
          if (regLicFileUrl) {
            return (
              <Button
                size="small"
                ghost
                onClick={() => {
                  window.open(
                    regLicFileUrl,
                    undefined,
                    "width=1000,height=800"
                  );
                }}
              >
                사업자 등록증 확인
              </Button>
            );
          }
          return "-";
        },
      },
      {
        label: "사업장 주소",
        type: FieldType.Text,
        content: corpAddr,
      },
      {
        label: "KIS 인증여부",
        content: kisAuthYn === "Y" ? "인증성공" : "인증실패",
      },
      {
        label: "추가 인증 여부",
        type: FieldType.Custom,
        render: () => {
          return (
            <Select
              disabled={!editable}
              value={addVrfYn}
              style={{
                width: "150px",
              }}
              showSearch={false}
              options={ENUM_BIZ_AUTH_OPTIONS}
              onChange={(value) => {
                showConfirm(
                  "인증상태변경",
                  `해당 사업자 정보를 "${BIZ_AUTH_TRANSLATION[value]}"상태로 변경하시겠습니까?`,
                  async () => {
                    await requestEditBizUserAuthState(busrCustId, value);
                  }
                );
              }}
            />
          );
        },
      },
      {
        label: "인증 처리 담당자",
        content: vrfUserNm,
      },

      {
        type: FieldType.Custom,
        span: 24,
        render: () => {
          return (
            <CustomSimulSection
              pldgRtText={businessInfo.corpPldgRtText}
              onClickEstButton={() => {
                handleEstimationSimulatorModal(businessInfo);
              }}
            />
          );
        },
      },
      {
        type: FieldType.Divider,
      },
      {
        label: "세금계산서 담당자",
        type: FieldType.LabelOnly,
      },
      {
        label: "이름",
        type: FieldType.Text,
        content: taxCustNm,
      },
      {
        label: "이메일",
        type: FieldType.Text,
        content: taxEmail,
      },
      {
        label: "휴대폰 번호",
        type: FieldType.Text,
        content: taxHpNo,
      },
      {
        label: "전화 번호",
        type: FieldType.Text,
        content: taxTelNo,
      },
    ];
  };

  // 개인 사업자 정보
  const getPrivateBusinessFieldInfo = (businessInfo: BusinessInfo) => {
    const {
      busrCustNm,
      busiNo,
      taxCustNm,
      taxEmail,
      taxTelNo,
      taxHpNo,
      addr,
      dtladdr,
      bzcd,
      bztp,
      addVrfYn,
      regLicFileUrl,
      busrCustId,
      kisAuthYn,
      vrfUserNm,
    } = businessInfo;

    let corpAddr = "";
    if (addr && dtladdr) {
      corpAddr = `${addr} ${dtladdr}`;
    }

    const custNm = customer?.custNm;

    return [
      {
        label: "개인사업자 정보",
        type: FieldType.LabelOnly,
      },
      // 사업자 수정 / 삭제
      {
        type: FieldType.Custom,
        span: 24,
        render: () => {
          return (
            <>
              <Button
                style={{
                  marginRight: "8px",
                }}
                onClick={() => {
                  handleBizEditClick(businessInfo);
                }}
              >
                수정
              </Button>
              <Button
                onClick={() => {
                  handleBizDelButton(businessInfo.busrCustId);
                }}
              >
                삭제
              </Button>
            </>
          );
        },
      },
      {
        label: "상호",
        type: FieldType.Text,
        content: busrCustNm,
      },
      {
        label: "사업자번호",
        type: FieldType.Text,
        content: busiNo,
      },
      {
        label: "대표자명",
        type: FieldType.Text,
        content: custNm,
      },
      {
        label: "업종",
        type: FieldType.Text,
        content: bztp,
      },
      {
        label: "업태",
        type: FieldType.Text,
        content: bzcd,
      },
      {
        label: "사업장 주소",
        type: FieldType.Text,
        content: corpAddr,
      },
      {
        label: "사업자 등록증",
        span: 12,
        type: FieldType.Custom,
        render: () => {
          if (regLicFileUrl) {
            return (
              <Button
                size="small"
                ghost
                onClick={() => {
                  window.open(
                    regLicFileUrl,
                    undefined,
                    "width=1000,height=800"
                  );
                }}
              >
                사업자 등록증 확인
              </Button>
            );
          }
          return "-";
        },
      },
      {
        label: "KIS 인증여부",
        content: kisAuthYn === "Y" ? "인증성공" : "인증실패",
      },
      {
        label: "추가 인증 여부",
        type: FieldType.Custom,
        render: () => {
          return (
            <Select
              disabled={!editable}
              value={addVrfYn}
              style={{
                width: "150px",
              }}
              showSearch={false}
              options={ENUM_BIZ_AUTH_OPTIONS}
              onChange={(value) => {
                showConfirm(
                  "인증상태변경",
                  `해당 사업자 정보를 "${BIZ_AUTH_TRANSLATION[value]}"상태로 변경하시겠습니까?`,
                  async () => {
                    await requestEditBizUserAuthState(busrCustId, value);
                  }
                );
              }}
            />
          );
        },
      },
      {
        label: "인증 처리 담당자",
        content: vrfUserNm,
      },
      // 견적내기 (개인사업자)
      {
        type: FieldType.Custom,
        span: 24,
        render: () => {
          return (
            <CustomSimulSection
              pldgRtText={customer?.pldgRtText || ""}
              onClickEstButton={() => {
                handleEstimationSimulatorModal(businessInfo);
              }}
            />
          );
        },
      },
      {
        type: FieldType.Divider,
      },
      {
        label: "세금계산서 담당자",
        type: FieldType.LabelOnly,
      },
      {
        label: "이름",
        type: FieldType.Text,
        content: taxCustNm,
      },
      {
        label: "이메일",
        type: FieldType.Text,
        content: taxEmail,
      },
      {
        label: "휴대폰 번호",
        type: FieldType.Text,
        content: taxHpNo,
      },
      {
        label: "전화 번호",
        type: FieldType.Text,
        content: taxTelNo,
      },
    ];
  };

  const renderDetail = () => {
    if (customer) {
      // 회원정보 섹션
      const customerInfoSection: Array<SectionFieldInfo> = [
        {
          label: "이름",
          type: FieldType.Text,
          content: customer.custNm,
        },
        {
          label: "회원구분",
          type: FieldType.Text,
          content: `${
            customer.empYn === "Y"
              ? `${customer.custCls}(임직원)`
              : customer.custCls
          }`,
        },
        {
          label: "휴대폰번호",
          type: FieldType.Text,
          content: customer.hpNo,
          span: 12,
        },
        {
          label: "생년월일",
          type: FieldType.Text,
          content: customer.birthDayText,
        },
        {
          label: "성별",
          type: FieldType.Text,
          content: customer.genderText,
        },
        {
          label: "면허 종류",
          type: FieldType.Text,
          content: customer.drvLcnsKind,
        },
        {
          label: "면허 번호",
          type: FieldType.Text,
          content: customer.drvLcnsNo,
        },
        {
          label: "SMS 수신동의여부",
          type: FieldType.Text,
          content: customer.smsAgreementText,
        },
        {
          label: "TM 수신동의여부",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <CustomTMAgreeSection
                custCls={customer.custCls}
                tmAgreeTxtValue={customer?.tmAgreementText} 
                onChange={(checked: boolean  ) => handleTMSwitch(checked  )}
              />
            );
          },
        },
        // 커스텀 타입 - 견적내기 (개인) 
        {
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <CustomSimulSection
                pldgRtText={customer?.pldgRtText}
                onClickEstButton={() => {
                  handleEstimationSimulatorModal();
                }}
              />
            );
          },
        },
        // 사용자 비고
        {
          type: FieldType.Custom,
          span: 12,
          render: () => {
            return (
              <CustomNoteSection
                usernoteText={customer?.remark}
                onClickNoteButton={(p) => {
                  handleUsernoteUpdateButton(p);
                }}
              />
            );
          },
        },
      ];

      const mgmInfoSection = [
        {
          label: "가입여부",
          type: FieldType.Text,
          content: customer.frnRecEntryYn ? "가입" : "미가입",
        },
        {
          label: "가입일시",
          type: FieldType.Text,
          content: customer.frnRecEntryDtm
            ? formatDateString(customer.frnRecEntryDtm, true)
            : "-",
        },
      ];

      // 카매니저 정보
      const carManagerInfoSection: Array<SectionFieldInfo> = [
        {
          label: "이름",
          type: FieldType.Text,
          content: customer.carmanNm,
        },
        {
          label: "휴대폰 번호",
          type: FieldType.Text,
          content: customer.carmanHpNo,
        },
        {
          label: "담당 지역",
          type: FieldType.Text,
          content: customer.carmanSiteNm,
        },
      ];

      if (customer.carmanId) {
        // 카매니저 이관 요청
        carManagerInfoSection.push({
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div>
                <Button
                  onClick={() => {
                    handleCarManagerTransferRequestButtonClick(
                      customer?.carmanId
                    );
                  }}
                >
                  이관요청
                </Button>
              </div>
            );
          },
        });
      }

      const { businessList } = customer;

      return (
        <div>
          <DetailSection label="회원정보" fieldInfos={customerInfoSection} />
          <DetailSection
            label="친구추천 프로그램"
            fieldInfos={mgmInfoSection}
          />
          <DetailSection
            label="카매니저 정보"
            fieldInfos={carManagerInfoSection}
          />
          {businessList.map((businessInfo) => {
            const { busrCustId, busrClsCd } = businessInfo;

            // 법인
            if (busrClsCd === ENUM_CORP_BUSINESS_CODE) {
              return (
                <DetailSection
                  key={busrCustId}
                  label="법인정보"
                  fieldInfos={getCorpFieldInfo(businessInfo)}
                />
              );
            }

            // 개인 사업자
            if (busrClsCd === ENUM_PRIVATE_BUSINESS_CODE) {
              return (
                <DetailSection
                  key={busrCustId}
                  label="개인사업자 정보"
                  fieldInfos={getPrivateBusinessFieldInfo(businessInfo)}
                />
              );
            }

            return "";
          })}
          <ModalTableWrapper headerTitle="견적관리">
            <ClientRowTable
              cols={estimationCols}
              rowData={estRows}
              frameworkComponents={{
                ...createActionCellRenderers(
                  undefined,
                  handleEstimationDetailClick,
                  {
                    hideDeleteButton: true,
                  }
                ),
              }}
            />
          </ModalTableWrapper>
          <Divider
            style={{
              backgroundColor: "#eeeff5",
            }}
          />
          <ModalTableWrapper headerTitle="계약내역">
            <ClientRowTable
              cols={contractCols}
              rowData={contractRows}
              frameworkComponents={{
                ...createActionCellRenderers(
                  undefined,
                  handleContractDetailClick,
                  {
                    hideDeleteButton: true,
                  }
                ),
              }}
            />
          </ModalTableWrapper>
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        title={strings.MODAL_TITLE_CUSTOMER_DETAIL}
        visible={visible}
        onCancel={onCancel}
        onOpen={onModalOpen}
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
        
        <CustomerInfoHistoryListModal
          visible={customerInfoHistoryModalVisible}
          onCancel={() => {
            setCustomerInfoHistoryModalVisible(false);
          }}
          custId={id}
        />
        <CarManagerTransferRequestModal
          visible={carManagerTransferRequestModalVisible}
          onCancel={() => {
            setCarManagerTransferRequestModalVisible(false);
          }}
          carmanId={carmanId}
          custId={customer?.custId}
        />
        <EstimationSimulatorModal
          visible={estimationSimulatorModalVisible}
          custId={customer?.custId}
          businessInfo={businessInfoForEstimate}
          onCancel={() => {
            setEstimationSimulatorModalVisible(false);
          }}
          onDataChange={async () => {
            if (id) {
              const customer = await requestFetchCustomerDetail(id);
              setCustomer(customer);
              if (onDataChange) onDataChange();
            }
          }}
        />
        <EstimationDetailModal
          id={selectedEstId}
          disableCustomerSection
          visible={estDetailModalVisible}
          onCancel={() => {
            setEstDetailModalVisible(false);
          }}
        />
        <RentContractDetailModal
          cntrId={selectedContractId}
          cntrSeq={selectedContractSeq}
          disableCustomerSection
          visible={rentContractDetailModalVisible}
          onCancel={() => {
            setRentContractEstDetailModalVisible(false);
          }}
        />
        {!dataFetching && bizInfo && <BusinessInfoEditModal
          businessInfo={bizInfo}
          visible={isVisibleEditBizInfo}
          onCancel={handleBizEditCancelClick}
          onDataChange={async () => {
            setDataFetching(true);
            const customerRes = requestFetchCustomerDetail(id!);
            setCustomer(await customerRes);
            setDataFetching(false);
          }}
        />}
      </Modal>
    </>
  );
};

CustomerModal.defaultProps = {
  onCancel: () => {},
  visible: false,
  id: undefined,
};

export default CustomerModal;
