import React, { useState } from "react";
import dompurify from "dompurify";
import Modal from "../../../../../components/shared/feedback/antd/Modal";
import { alertSuccess, alertError, renderLoading } from "../../../../../utils/render-utils";

import UsedCarCatalogDetail from "../../../../../models/UsedCarCatalogDetail";
import DetailSection, {
  FieldType,
  SectionFieldInfo,
} from "../../../../../components/shared/layout/DetailSection";
import {
  formatCurrency,
  formatNumber,
  getErrorMessage,
} from "../../../../../utils/common-utils";
import { ModalProps } from "../../../../types";
import {
  deleteUcRntFeeWhenNoSetFlag,
  deleteUcTkvAmtWhenNoSetFlag,
  fetchUCCatalogDetail,
  updateUsdCarOptStsUpd,
  updateUC3dYnState,
} from "../../../../../apis/uc-catalogs";
import { formatDateString } from "../../../../../utils/date-utils";
import ClientRowTable from "../../../../../components/shared/data-display/Tables/components/ClientRowTable";
import {
  createTableCol,
  FilterType,
} from "../../../../../components/shared/data-display/Tables/model/TableColumn";
import { CW_LARGE, CW_MEDIUM } from "../../../../../utils/table-utils";
import Button from "../../../../../components/shared/general/antd/Button";
import Image from "../../../../../components/shared/data-display/antd/Image";
import MdCommentFormModal from "./pages/MdCommentFormModal";
import UsedCarImageManagerModal from "./pages/UsedCarImageManagerModal";
import UsedCarImageSortOrderModal from "./pages/UsedCarImageSortOrderModal";
import { StyledTable } from "../../../../../components/shared/data-display/Tables/components/StyledTable";
import RntFeeSetModal from "./pages/RntFeeSetModal";
import TkvAmtSetModal from "./pages/TkvAmtSetModal";
import {
  ENUM_DC_TYPE_PERCENT,
  getUcProdType,
} from "../../../../../constants/enums";
import FormItem from "../../../../../components/shared/data-entry/antd/FormItem";
import UsedCarProdTypeModifyModal from "./pages/UsedCarProdTypeModifyModal";
import {
  MOBILE_TAG_RENDERER,
  PC_TAG_RENDERER,
} from "../../../CatalogTagManager/utils/table-utils";
import {
  mobileTagRenderer,
  pcTagCellRenderer,
} from "../../../../../components/shared/data-display/Tables/components/AgGridCellRenderers/utils/ag-grid-render-utils";
import { createCatalogTagCols } from "./utils/table-utils";
import CatalogTagSetupModal from "./pages/CatalogTagSetupModal";
import TagPreviewModal from "./pages/TagPreviewModal";
import VideoRegistrationModal from "./pages/VideoRegistrationModal";
import UsedCarClassNmModifyModal from "./pages/UsedCarClassNmModifyModal";
import UsedCarGradeNmModifyModal from "./pages/UsedCarGradeNmModifyModal";
import UsedCarYearTypeModifyModal from "./pages/UsedCarYearTypeModifyModal";
import UsedCarMainOptionAddModal from "./pages/UsedCarMainOptionAddModal";

// 3d image 사용여부 제어
import Switch from "@components/shared/data-entry/antd/Switch";
import { showConfirm } from "@components/shared/feedback/Confirm";

interface Props extends ModalProps {
  dataId?: string;
}

const UsedCarCatalogModal: React.FC<Props> = (props: Props) => {
  const { visible, onCancel, dataId, onDataChange } = props;
  const [dataFetching, setDataFetching] = useState(false);
  const [catalogDetail, setCatalogDetail] = useState<UsedCarCatalogDetail>();
  const [mdCommentFormModalVisible, setMdCommentFormModalVisible] = useState(false);
  const [imageSortOrderModalVisible, setImageSortOrderModalVisible] = useState(false);
  const [imageManagerModalVisible, setImageManagerModalVisible] = useState(false);
  const [tagSetupModalVisible, setTagSetupModalVisible] = useState(false);
  const [tagPreviewModalVisible, setTagPreviewModalVisible] = useState(false);
  const [rntFeeSetModalVisible, setRntFeeSetMdoalVisible] = useState(false);
  const [tkvAmtSetModalVisible, setTkvAmtSetModalVisible] = useState(false);
  const [prodTypeModifyModalVisible, setProdTypeModifyModalVisible] = useState(false);
  const [classModifyModalVisible, setClassModifyModalVisible] = useState(false);
  const [carGradeNmModifyModalVisible, setCarGradeNmModifyModalVisible] = useState(false);
  const [yearTypeModifyModalVisible, setYearTypeModifyModalVisible] = useState(false);
  const [mainOptionAddModalVisible, setMainOptionAddModalVisible] = useState(false);
  // 3d image 사용여부 제어
  const [switchLoading, setSwitchLoading] = useState(false);

  const [
    videoRegistrationModalVisible,
    setVideoRegistrationModalVisible,
  ] = useState(false);

  const tagCols = createCatalogTagCols();

  const onModalOpen = async () => {
    setDataFetching(true);
    await requestFetchCatalogDetail();
    setDataFetching(false);
  };

  /**
   * Private Functions
   */
  const requestFetchCatalogDetail = async () => {
    if (dataId) {
      try {
        const catalogDetail = await fetchUCCatalogDetail(dataId);
        setCatalogDetail(catalogDetail);
      } catch (e) {
        alertError(getErrorMessage(e));
        setCatalogDetail(undefined);
      }
    }
  };

  /**
   * Event Actions
   */

  const handleImageSortOrderClick = () => {
    setImageSortOrderModalVisible(true);
  };

  const handleImageManagerClick = () => {
    setImageManagerModalVisible(true);
  };

  const handleMdContentClick = () => {
    setMdCommentFormModalVisible(true);
  };

  const handleTagSetupButtonClick = () => {
    setTagSetupModalVisible(true);
  };

  const handleTagPreviewButtonClick = () => {
    setTagPreviewModalVisible(true);
  };

  const handleVideoRegistrationButtonClick = () => {
    setVideoRegistrationModalVisible(true);
  };

  const handleUpdateUsdCarOptStsUpd = async (
    modeGrdId: string,
    cossModlId: string,
    optNm: string
  ) => {
    const body = {
      modeGrdId,
      cossModlId,
      optNm,
      optSts: "N",
    };

    try {
      await updateUsdCarOptStsUpd(body);
      alertSuccess("옵션을 제거하였습니다.");

      setDataFetching(true);
      await requestFetchCatalogDetail();
      setDataFetching(false);
    } catch (e) {
      alertError(getErrorMessage(e));
    }
  };

  /**
   * 3D image 사용여부 confirm
   */
  const handle3dYnStateSwitchChange = async (checked: boolean) => {
    showConfirm(
      `3D 이미지를 ${checked ? '' : '미'}사용으로 변경`,
      `해당상품의 3D 이미지를 ${checked ? '' : '미'}사용상태로 변경하시겠습니까?`,
      async () => {
        await requestUpdate3dImageState(checked);
      }
    );
  };

  /**
   * 3D image 사용여부 제어
   * 
   * @param checked 
   */
  const requestUpdate3dImageState = async (checked: boolean) => {
    setSwitchLoading(true);
    if (catalogDetail) {
      const view3dYn = checked ? "Y" : "N";
      if (catalogDetail) {
        catalogDetail.view3dYn = view3dYn;
      }
      setCatalogDetail(catalogDetail);
      try {
        await updateUC3dYnState(catalogDetail.prodId, catalogDetail.carId, view3dYn);
        alertSuccess(`3D 이미지가 ${checked ? '' : '미'}사용 처리되었습니다`);
        if (onDataChange) onDataChange();
      } catch (e) {
        alertError(getErrorMessage(e));
      } finally {
        setSwitchLoading(false);
      }
    }
  };

  /**
   * Render Helpers
   */

  const renderColorChip = (
    colorName: string,
    colorImgUrl: string,
    cossColorNm: string
  ) => {
    return (
      <div>
        <div>
          {cossColorNm && (
            <div
              style={{
                marginBottom: "8px",
              }}
            >
              {cossColorNm}
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {colorImgUrl && (
              <img
                style={{
                  borderRadius: "9px",
                  marginRight: "8px",
                  overflow: "hidden",
                  display: "inline-block",
                }}
                width={18}
                height={18}
                src={colorImgUrl}
                alt=""
              />
            )}

            <span
              style={{
                color: "#626BFF",
              }}
            >
              {colorName}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (catalogDetail) {
      const {
        yearType,
        curTravelDtc,
        modeFuelNm,
        engdisp,
        seaterClsNm,
        grbxNm,
        regDt,
        carNo,
        xtnlCarColorNm,
        xtnlColorImgUrl,
        innrCarColorNm,
        innrColorImgUrl,
        mainOptNms = "",
        fixContList,
        mdComment,
        carImgList = [],
        totOptAmt,
        prodNm,
        cossXtnlCarColorNm,
        cossInnrCarColorNm,
        ncarClsFlag,
        tagList,
        carClassNm,
        modeGrdId,
        cossModlId,
        carGradeNm,
        view3dYn,
      } = catalogDetail;

      /**
       * 차량정보
       */
      const carInfoSection = [
        {
          label: "3D 이미지 사용 여부",
          type: FieldType.Custom,
          span: 24,
          render: () => 
            <Switch
              checked={view3dYn === 'Y'}
              loading={switchLoading}
              defaultChecked={view3dYn === 'Y'}
              onChange={handle3dYnStateSwitchChange}
            />
        },
        {
          label: "차량이미지",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            if (carImgList && carImgList.length > 0) {
              return carImgList.map(({ fileUrl }) => {
                return (
                  <Image
                    style={{ marginRight: "8px" }}
                    key={fileUrl}
                    src={fileUrl}
                    width={102}
                    height={76}
                  />
                );
              });
            }

            return "-";
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <Button
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={handleImageManagerClick}
                >
                  수정하기
                </Button>
                <Button onClick={handleImageSortOrderClick}>
                  게시순번 관리
                </Button>
              </div>
            );
          },
        },
        {
          label: "차량소개",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            if (mdComment) {
              return (
                <div
                  dangerouslySetInnerHTML={{
                    __html: dompurify.sanitize(mdComment),
                  }}
                />
              );
            }

            return "-";
          },
        },
        {
          label: "",
          type: FieldType.Custom,
          render: () => {
            return <Button onClick={handleMdContentClick}>수정하기</Button>;
          },
        },
      ];

      /**
       * 태그 관리
       */
      const tagInfoSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <Button
                  style={{
                    marginRight: "8px",
                  }}
                  onClick={handleTagSetupButtonClick}
                >
                  설정
                </Button>
                <Button ghost onClick={handleTagPreviewButtonClick}>
                  미리보기
                </Button>
                <div
                  style={{
                    marginTop: "8px",
                    height: "300px",
                  }}
                >
                  <ClientRowTable
                    cols={tagCols}
                    rowData={tagList || []}
                    frameworkComponents={{
                      [PC_TAG_RENDERER]: pcTagCellRenderer,
                      [MOBILE_TAG_RENDERER]: mobileTagRenderer,
                    }}
                  />
                </div>
              </div>
            );
          },
        },
      ];

      /**
       * 정비이력
       */
      const fixInfoSection = [
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <div
                style={{
                  height: "260px",
                }}
              >
                <ClientRowTable
                  cols={[
                    createTableCol("inDt", "입고일", undefined, {
                      width: CW_MEDIUM,
                      cellRenderer: formatDateString("inDt"),
                    }),
                    createTableCol("dtlNm", "정비명", FilterType.Text, {
                      width: CW_LARGE,
                      flex: 1,
                    }),
                  ]}
                  rowData={fixContList}
                />
              </div>
            );
          },
        },
      ];

      /**
       * 기본정보
       */
      let existYnMainOptNms = true;
      let mainOptNmsTmp = "";

      if(mainOptNms === null) {
        mainOptNmsTmp = "";
        existYnMainOptNms = false;
      } else {
        mainOptNmsTmp = mainOptNms;
        existYnMainOptNms = true;
      }

      const mainOptions = mainOptNmsTmp.split(" / ");
      const basicInfoSection = [
        {
          label: "차량명",
          type: FieldType.Text,
          content: prodNm,
        },
        {
          type: FieldType.Custom,
          render: () => {
            return (
              <FormItem label="연식" labelCol={{ span: 24, offset: 0 }}>
                <>
                  <p>{yearType}</p>
                  <Button
                    onClick={() => {
                      setYearTypeModifyModalVisible(true);
                    }}
                  >
                    수정
                  </Button>
                </>
              </FormItem>
            );
          },
        },
        {
          label: "주행거리",
          type: FieldType.Custom,
          render: () => {
            return `${formatNumber(curTravelDtc)}km`;
          },
        },
        {
          label: "연료",
          type: FieldType.Text,
          content: modeFuelNm,
        },
        {
          label: "배기량",
          type: FieldType.Custom,
          render: () => {
            return `${formatNumber(engdisp)}cc`;
          },
        },
        {
          label: "인승",
          type: FieldType.Text,
          content: seaterClsNm,
        },
        {
          label: "변속기",
          type: FieldType.Text,
          content: grbxNm,
        },
        {
          label: "제조사 신차가격",
          type: FieldType.Custom,
          render: () => {
            return formatCurrency(totOptAmt);
          },
        },
        {
          label: "최초 등록일",
          type: FieldType.Custom,
          render: () => {
            return formatDateString(regDt);
          },
        },
        {
          label: "차량번호",
          type: FieldType.Text,
          content: carNo,
        },
        {
          type: FieldType.Custom,
          render: () => {
            return (
              <FormItem label="상품유형" labelCol={{ span: 24, offset: 0 }}>
                <>
                  <p>{getUcProdType(ncarClsFlag)}</p>
                  {/* <Button
                    onClick={() => {
                      setProdTypeModifyModalVisible(true);
                    }}
                  >
                    변경
                  </Button> */}
                </>
              </FormItem>
            );
          },
        },
      ];

      /**
       * 차량 세부모델명
       */       
       const carClassNmSection = [         
         {
           type: FieldType.Custom,
           render: () => {
             return (
               <FormItem label="세부모델명" labelCol={{ span: 24, offset: 0 }}>
                 <>
                   <p>{carClassNm}</p>
                   <Button
                     onClick={() => {
                      setClassModifyModalVisible(true);
                     }}
                   >
                     변경
                   </Button>
                 </>
               </FormItem>
             );
           },
         },
         {
          type: FieldType.Custom,
          render: () => {
            return (
              <FormItem label="트림" labelCol={{ span: 24, offset: 0 }}>
                <>
                  <p>{carGradeNm}</p>
                  <Button
                    onClick={() => {
                      setCarGradeNmModifyModalVisible(true);
                    }}
                  >
                    변경
                  </Button>
                </>
              </FormItem>
            );
          },
        },
       ];

      const mainOptionSectionElements = mainOptions?.map((optionName, index) => {
        return {
          type: FieldType.Custom,
          render: () => {
            if(existYnMainOptNms) {
              return (
                <FormItem label={`옵션-${index + 1}`} labelCol={{ span: 24, offset: 0 }}>
                  <>
                    <p>{optionName}</p>
                    <Button
                      type="ghost"
                      onClick={() => {
                        handleUpdateUsdCarOptStsUpd(
                          modeGrdId,
                          cossModlId,
                          optionName
                        );
                      }}
                    >
                      제거
                    </Button>
                  </>
                </FormItem>
              );
            } else {
              return (
                <FormItem label="옵션" labelCol={{ span: 24, offset: 0 }}>
                  <>
                    <p>옵션없음.</p>
                  </>
                </FormItem>
              );
            }
          },
        };
      });

      /**
       * 옵션정보
       */
      const optionInfoSection = [
        {
          label: "색상정보",
          type: FieldType.LabelOnly,
          content: "",
        },
        {
          label: "외장색상",
          type: FieldType.Custom,
          render: () => {
            return renderColorChip(
              xtnlCarColorNm,
              xtnlColorImgUrl,
              cossXtnlCarColorNm
            );
          },
        },
        {
          label: "내장색상",
          type: FieldType.Custom,
          render: () => {
            return renderColorChip(
              innrCarColorNm,
              innrColorImgUrl,
              cossInnrCarColorNm
            );
          },
        },
        {
          type: FieldType.Divider,
        },
        {
          label: "주요옵션",
          type: FieldType.LabelOnly,
          content: "",
        },
        ...mainOptionSectionElements,
        {
          label: "옵션생성",
          type: FieldType.Custom,
          content: "",
          render: () => {
            return (
              <FormItem labelCol={{ span: 24, offset: 0 }}>
                <>
                  <Button
                    onClick={() => {
                      setMainOptionAddModalVisible(true);
                    }}
                  >
                    추가
                  </Button>
                </>
              </FormItem>
            );
          },
        },
        {
          type: FieldType.Divider,
        },
        // {
        //   label: "추가옵션",
        //   type: FieldType.LabelOnly,
        //   content: "",
        // },
        // {
        //   type: FieldType.Divider,
        // },
        // {
        //   label: "서비스옵션",
        //   type: FieldType.LabelOnly,
        //   content: "",
        // },
        // {
        //   type: FieldType.Divider,
        // },
      ];

      /**
       * 렌탈료
       */
      const rentFeeSection = [
        {
          label: "",
          type: FieldType.Custom,
          render: () => (
            <Button
              onClick={() => setRntFeeSetMdoalVisible(true)}
              style={{ marginTop: "24px" }}
            >
              설정
            </Button>
          ),
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <StyledTable>
                <thead>
                  <tr>
                    <th>개월수</th>
                    {catalogDetail?.rentFeeSetList.map(
                      ({ monthInfo }, index) => {
                        return <th key={index.toString()}>{monthInfo}개월</th>;
                      }
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>실주행거리</th>
                    {catalogDetail?.rentFeeSetList.map(({ realKm }, index) => {
                      return (
                        <td key={index.toString()}>
                          {Number(realKm).toLocaleString()}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>표준렌탈료</th>
                    {catalogDetail?.rentFeeSetList.map(({ rntAmt }, index) => {
                      return (
                        <td key={index.toString()}>
                          {Number(rntAmt).toLocaleString()}
                        </td>
                      );
                    })}
                  </tr>
                  {/* <tr>
                    <th>시스템조정가</th>
                    {catalogDetail?.rentFeeSetList.map(
                      ({ rntAmtTune }, index) => {
                        return (
                          <td key={index.toString()}>
                            {Number(rntAmtTune).toLocaleString()}
                          </td>
                        );
                      }
                    )}
                  </tr> */}
                  <tr className="rate-control">
                    <th>조정</th>
                    {catalogDetail?.rentFeeSetList.map(
                      ({ dcVal, dcTypeCd }, index) => {
                        return (
                          <td key={index.toString()}>
                            {Number(dcVal).toLocaleString()}
                            {dcTypeCd === ENUM_DC_TYPE_PERCENT ? "%" : ""}
                          </td>
                        );
                      }
                    )}
                  </tr>
                  <tr>
                    <th>최종 표준렌탈료</th>
                    {catalogDetail?.rentFeeSetList.map(
                      ({ finalRntAmt }, index) => {
                        return (
                          <td key={index.toString()}>
                            {finalRntAmt.toLocaleString()}
                          </td>
                        );
                      }
                    )}
                  </tr>
                </tbody>
              </StyledTable>
            );
          },
        },
      ];

      /**
       * 인수가
       */
      const tkvAmtSection = [
        {
          label: "",
          type: FieldType.Custom,
          render: () => (
            <Button
              onClick={() => setTkvAmtSetModalVisible(true)}
              style={{ marginTop: "24px" }}
            >
              설정
            </Button>
          ),
        },
        {
          label: "",
          type: FieldType.Custom,
          span: 24,
          render: () => {
            return (
              <StyledTable>
                <thead>
                  <tr>
                    <th>개월수</th>
                    {catalogDetail?.tkvAmtSetList.map(
                      ({ monthInfo }, index) => {
                        return <th key={index.toString()}>{monthInfo}개월</th>;
                      }
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>인수가</th>
                    {catalogDetail?.tkvAmtSetList.map(({ tkvAmt }, index) => {
                      return (
                        <td key={index.toString()}>
                          {Number(tkvAmt).toLocaleString()}
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <th>조정인수가</th>
                    {catalogDetail?.tkvAmtSetList.map(
                      ({ tkvAmtTune }, index) => {
                        return (
                          <td key={index.toString()}>
                            {Number(tkvAmtTune).toLocaleString()}
                          </td>
                        );
                      }
                    )}
                  </tr>
                  <tr className="rate-control">
                    <th>조정</th>
                    {catalogDetail?.tkvAmtSetList.map(
                      ({ tkvAmtTune, tkvAmt }, index) => {
                        return (
                          <td key={index.toString()}>
                            {Number(tkvAmtTune) - Number(tkvAmt) > 0 && "+"}
                            {Number(tkvAmtTune) - Number(tkvAmt) < 0 && "-"}
                            {(
                              Number(tkvAmtTune) - Number(tkvAmt)
                            ).toLocaleString()}
                          </td>
                        );
                      }
                    )}
                  </tr>
                </tbody>
              </StyledTable>
            );
          },
        },
      ];

      /**
       * 비디오관리
       */
      const renderVideoRate = (value: string) => {
        return value === "W" ? "가로형" : "세로형";
      };

      const videoInfoSection: SectionFieldInfo[] = [
        {
          label: "",
          span: 24,
          type: FieldType.Custom,
          render: () => {
            return (
              <div>
                <Button
                  style={{
                    marginRight: "8px",
                  }}
                  onClick={handleVideoRegistrationButtonClick}
                >
                  설정
                </Button>
              </div>
            );
          },
        },
        {
          label: "영상 비율",
          content: catalogDetail?.videoList[0]
            ? renderVideoRate(catalogDetail.videoList[0].videoRate)
            : "-",
        },
        {
          label: "버튼명",
          content: catalogDetail?.videoList[0]
            ? catalogDetail.videoList[0].videoBtn
            : "-",
        },
        {
          label: "동영상 URL",
          content: catalogDetail?.videoList[0]
            ? catalogDetail.videoList[0].videoUrl
            : "-",
        },
        {
          label: "사용 여부",
          content: catalogDetail?.videoList[0]
            ? catalogDetail.videoList[0].useYn
            : "-",
        },
      ];

      return (
        <div>
          <DetailSection label="차량정보" fieldInfos={carInfoSection} />
          <DetailSection label="태그 관리" fieldInfos={tagInfoSection} />
          <DetailSection label="비디오 관리" fieldInfos={videoInfoSection} />
          <DetailSection label="정비이력" fieldInfos={fixInfoSection} />
          <DetailSection label="기본정보" fieldInfos={basicInfoSection} />
          <DetailSection label="차량 세부모델명" fieldInfos={carClassNmSection} />
          <DetailSection
            label="옵션정보"
            fieldInfos={optionInfoSection}
            hideDivider
          />
          <DetailSection label="렌탈료" fieldInfos={rentFeeSection} />
          <DetailSection label="인수가" fieldInfos={tkvAmtSection} />
        </div>
      );
    }
    return "";
  };

  return (
    <>
      <Modal
        size="large"
        onOpen={onModalOpen}
        visible={visible}
        onCancel={onCancel}
        title="상품 상세 정보"
        footer={null}
      >
        {dataFetching ? renderLoading() : renderDetail()}
      </Modal>
      <MdCommentFormModal
        visible={mdCommentFormModalVisible}
        onCancel={() => {
          setMdCommentFormModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <UsedCarImageManagerModal
        visible={imageManagerModalVisible}
        onCancel={() => {
          setImageManagerModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <UsedCarImageSortOrderModal
        visible={imageSortOrderModalVisible}
        onCancel={() => {
          setImageSortOrderModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <RntFeeSetModal
        visible={rntFeeSetModalVisible}
        onClose={() => setRntFeeSetMdoalVisible(false)}
        onCancel={() => {
          if (catalogDetail?.prodId) {
            deleteUcRntFeeWhenNoSetFlag(catalogDetail.prodId).finally(() =>
              setRntFeeSetMdoalVisible(false)
            );
          }
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <TkvAmtSetModal
        visible={tkvAmtSetModalVisible}
        onClose={() => {
          setTkvAmtSetModalVisible(false);
        }}
        onCancel={() => {
          if (catalogDetail?.prodId) {
            deleteUcTkvAmtWhenNoSetFlag(catalogDetail.prodId).finally(() =>
              setTkvAmtSetModalVisible(false)
            );
          }
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <UsedCarYearTypeModifyModal
        visible={yearTypeModifyModalVisible}
        onCancel={() => {
          setYearTypeModifyModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <UsedCarProdTypeModifyModal
        visible={prodTypeModifyModalVisible}
        onCancel={() => {
          setProdTypeModifyModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <UsedCarClassNmModifyModal
        visible={classModifyModalVisible}
        onCancel={() => {
          setClassModifyModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <UsedCarGradeNmModifyModal
        visible={carGradeNmModifyModalVisible}
        onCancel={() => {
          setCarGradeNmModifyModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <UsedCarMainOptionAddModal
        visible={mainOptionAddModalVisible}
        onCancel={() => {
          setMainOptionAddModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <CatalogTagSetupModal
        visible={tagSetupModalVisible}
        onCancel={() => {
          setTagSetupModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        onDataChange={async () => {
          await requestFetchCatalogDetail();
          if (onDataChange) onDataChange();
        }}
      />
      <TagPreviewModal
        visible={tagPreviewModalVisible}
        onCancel={() => {
          setTagPreviewModalVisible(false);
        }}
        catalogDetail={catalogDetail}
        tagMasters={catalogDetail && (catalogDetail.tagList || [])}
      />
      {catalogDetail && (
        <VideoRegistrationModal
          prodId={catalogDetail.prodId}
          visible={videoRegistrationModalVisible}
          onCancel={() => setVideoRegistrationModalVisible(false)}
          videoInfo={
            catalogDetail?.videoList.length
              ? catalogDetail.videoList[0]
              : undefined
          }
          onDataChange={async () => {
            await requestFetchCatalogDetail();
            if (onDataChange) onDataChange();
          }}
        />
      )}
    </>
  );
};

UsedCarCatalogModal.defaultProps = {
  onCancel: () => {},
  visible: false,
  dataId: undefined,
};
export default UsedCarCatalogModal;
