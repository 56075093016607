import ProductCondition from "@models/EstimationSimulator/ProductCondition";
import { RentFeeSetResVO } from "@models/RentFeeSet";

/**
 * 중고장기 상품 견적 조건 조회(GET: ~​/admin​/uc-rnt-fee-cond) 요청
 */
export interface UcRntFeeCondParam {
  // 차량번호
  carNo: string;
}

/**
 * 중고장기 상품 견적 조건 조회(GET: ~​/admin​/uc-rnt-fee-cond) 응답
 */
export class UcRntFeeCond {
  // 차량금액
  public carAmt: string = "";
  // 주행거리
  public curTravelDtc: string = "";
  // 모델명
  public modlNm: string = "";
  // 유종
  public repFuelNm: string = "";
  // 연식
  public yearType: string = "";
  // 상품조건
  public prodCondList: Array<ProductCondition> = [];
  // 차령
  public carAge: string = "";
}

/**
 * 중고장기 상품 차량번호 렌탈료 조회(GET: ~​/admin​/uc-rnt-fee-car-no) 요청
 */
export interface UcRntFeeCarNoParam {
  // 차량번호
  carNo: string;

  /**
   * 약정주행거리
   *  - 402008: 1만Km 이하
   *  - 402003: 2만Km 이하
   *  - 402003: 3만Km 이하
   */
  prmsDtc: string;

  /** 보증금 (0~60) */
  deposit: number;

  /**
   * 대물
   *  - 703006: 1억
   *  - 703007: 2억
   */
  impsnl: string;

  /**
   * 운전자 범위
   *  - 707006: 가족(직계, 형제자매)
   *  - 707007: 가족(직계, 형제자매)+1인추가
   *  - 707008: 가족(직계, 형제자매)+2인추가
   *  - 707009: 가족(직계, 형제자매)/직원
   *  - 707010: 가족(직계, 형제자매)/직원+1인추가
   *  - 707011: 가족(직계, 형제자매)/직원+2인추가
   *  - 707012: 임직원한정(임직원특약가입)
   *  - 707013: 임직원/임원직계가족(임직원특약미가입)
   *  - 707014: 임직원/임원직계가족+1인추가(임직원특약미가입)
   *  - 707015: 임직원/임원직계가족+2인추가(임직원특약미가입)
   */
  drvSoe: string;
}

/**
 * 중고장기 상품 견적 조건 조회(GET: ~​/admin​/uc-rnt-fee-cond) 응답
 */
export class UcRntFeeCarNo {
  // 렌탈료 목록 수
  public rntFeeSetlistCnt: string = "";
  // 렌탈료 목록
  public rntFeeSetlist: RentFeeSetResVO[] = [];
}