import { Switch } from "antd";
import React from "react";
import { ContentText } from "../../shared/layout/DetailSection/style";


interface CustomTMAgreeSectionProps {
  custCls: string;
  tmAgreeTxtValue: string;
  onChange:(checked: boolean)=> void
}

const CustomTMAgreeSection: React.FC<CustomTMAgreeSectionProps> = (props) => {

  const { custCls, tmAgreeTxtValue , onChange  } = props;

  return (
      <div  style={{ marginRight: "10px",}}>
        {custCls === "가회원" &&  (
          <Switch
            checked={tmAgreeTxtValue === "동의"}
          
            onChange={value => onChange(value)}
          />
        )}
        <span
          style={custCls === "가회원" ?{
            marginLeft: "12px",
            color: "#626DFF",
          } : {
            color: "#9698a8",
          }}
        >
          {tmAgreeTxtValue}
        </span>
      </div>
  );
  };

  export default CustomTMAgreeSection;