import React, { CSSProperties } from "react";
import { TableHeaderTitle } from "./style";
import { TableHeaderAction } from "../TableLayout";
import TableDataActionButton from "@components/shared/composition/TableDataActionButton";

interface Props {
  children?: React.ReactNode;
  headerTitle?: string;
  style?: CSSProperties;
  leftActions?: Array<TableHeaderAction>;
}

const ModalTableWrapper: React.FC<Props> = (props: Props) => {
  const { children, headerTitle, style, leftActions } = props;
  /**
   * Private Functions
   */

  /**
   * Event Actions
   */

  /**
   * Render Helpers
   */

  return (
    <div style={style}>
      {headerTitle && <TableHeaderTitle>{headerTitle}</TableHeaderTitle>}
      <div
        style={{
          width: "100%",
          height: "500px",
        }}
      >
        {leftActions && <div
          style={{
            position: "absolute",
            top: 0,
            left: 180,
          }}
        >
          {leftActions.map(
            ({
              customView = false,
              render,
              title,
              type = "primary",
              ...rest
            }) => {
              if (customView) {
                if (render) return render();
              }
              return (
                <TableDataActionButton
                  type={type}
                  key={title}
                  style={{
                    marginLeft: "8px",
                  }}
                  {...rest}
                >
                  {title}
                </TableDataActionButton>
              );
            }
          )}
        </div>}
        {children}
      </div>
    </div>
  );
};

ModalTableWrapper.defaultProps = {};
export default ModalTableWrapper;
